.login-link{
    color: blue;
    text-decoration: underline;
    cursor: pointer;
}

.login-form>form>button{
    color: white;
    background-color: rgb(151, 0, 0);
    padding: 10px;
    font-size: 1rem;
    text-align: center;
    margin-top: 10px;
    cursor: pointer;
}
.login-form>form{
    text-align: center;
}
.login-footer{
    text-align: center;
    margin-top: 10px;
}