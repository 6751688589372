.navbar{
    background-color: black;
    color: white;
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 1fr 1fr 1fr;
    text-align: center;
    align-items: center;
    padding-bottom: 10px;
    padding-top: 10px;
    margin-bottom: 20px;
}

@media screen and (max-width: 360px) {
    .navbar{
        grid-template-columns: 2fr 1fr 1fr;
    }
    .navbar>div{
        margin-top: 7px;
    }
}
.navbar>div>a{
    color: white;
    text-decoration: none;
    padding: 5px;
}
.navbar>div>a:hover{
    background-color: rgb(134, 0, 0);
}
.brand{
    font-size: 1.5rem;
}