.unit-card{
    border: 3px solid black;
    border-radius: 8px;
    padding: 8px;
    page-break-inside: avoid;
    font-weight: bold;
    background-color: white;
}
.unit-card-top{
    display: flex;
    column-gap: 2px;
    padding-bottom: 5px;
}
.weapon-headers, .weapon{
    display: grid;
    grid-template-columns: 110px 60px 70px 50px 3fr 50px 60px;
}
.weapon-headers.print, .weapon.row-1{
    grid-template-columns: 2fr 1fr 1fr 1fr 1fr 1fr;
    padding-left:0px;
}
.weapon.row-2{
    grid-template-columns: 1fr;
    padding-left: 0px;
}
.weapon.row-1>div,.weapon.row-2>div{
    padding-left: 5px;
}
.weapon-headers{
    text-align: center;
    letter-spacing: 2px;
}
.weapon-headers > *{
    border: 1px solid black;
}

.unit-weapons>div>div, .special>div{    
    padding-left: 5px;
}
.health, .defense, .save{
    padding-left: 0px;
    margin-left: 10px;
}
.weapon > *{
    border: 1px solid black;
}

.unit-specials{
}

.special{
    display: grid;
    grid-template-columns: 145px 1fr;
}

.special > *{
    border: 1px solid black;
}

.health-wrapper{
    display: grid;
    grid-template-columns: 70px 1fr 40px;
    column-gap: 8px;
}
.infantry-health-wrapper{
    display: grid;
    grid-template-columns: 60px 1fr;
    column-gap: 8px;
}

.total-cost{
    text-align: center;
}

.unit-image{
    width: 100%;
    max-width: 150px;
}
.unit-image-wrapper{
    margin-left: 0px;
}
.save-wrapper{
    display: grid;
    grid-template-columns: 1fr 40px;
}
.weapon>div:nth-child(2), .weapon>div:nth-child(3), .weapon>div:nth-child(4), .weapon>div:nth-child(6), .weapon>div:nth-child(7) {
    text-align: center;
}

.specials-title{
    text-align: center;
}

.order-name{
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 1.1rem;
    font-weight: bold;
    text-decoration: underline;
}

.order-name, .order-description{
    text-align: center;
}

.print-unit-wrapper{
    flex-wrap: wrap;
}
.unit-top-left{
    min-width: 111px;
}