:root {
    --red: rgb(134, 0, 0);
}
body{
    font-family: 'Oxanium';
    margin: 0px;
}
.list-builder{ 
    display: grid;
    grid-template-columns: 500px minmax(500px, 1fr) 500px;
}
.main{
    max-width: 1800px;
    margin: auto;
}

.unit-info{
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: rgb(230, 230, 230);
    border-radius: 8px;
    margin-left: 10px;
    margin-right: 10px;
}
.unit-options, .list{
    padding: 30px;
    padding-top: 40px;
    padding-bottom: 40px;
    background-color: rgb(230, 230, 230);
    border-radius: 8px;
}
@media screen and (max-width: 1800px) {
    .list-builder-page{
        padding-left: 5px;
        padding-right: 5px;
    }
}

@media screen and (max-width: 1500px) {
    .list-builder{
        grid-template-columns: 1fr;
    }
    .unit-card{
        margin-left: 0px;
    }
    .list-builder-page{
        padding-left: 0px;
        padding-right: 0px;
    }
    .list-builder-page>div>div{
        margin-bottom: 20px;
    }
    .list, .unit-options{
        padding-left: 10px;
        padding-right: 10px;
        margin-left: 0px;
    }
}
.option-unit-name{
    cursor: pointer;
    padding: 10px;
    font-size: 1.3rem;
    margin-bottom: 8px;
    box-shadow: 8px 8px 12px rgba(0, 0, 0, 0.212), -3px -3px 2px white;
    letter-spacing: 2px;
    background-color: white;
    border-radius: 5px;
    border-bottom: 3px solid rgb(78, 78, 78);
    font-weight: 600;
    text-align: center;
}

.list-unit-wrapper>.option-unit-name{
    margin-left: 20px;
}
.faction{
    text-align: center;
    width: 600px;
    margin: auto;
    margin-bottom: 10px;
    border-radius: 8px;
    padding-top: 10px;
    padding-bottom: 10px;
}


@media screen and (max-width: 800px) {
    .faction{
        width: 90vw;
        font-size: 1rem;
    }
}
.option-unit-name:hover{
    background-color: #4d4d4dc2; 
    color: white;
    box-shadow: 0px 0px 0px black;
}
.add-button{
    cursor: pointer;
    padding: 10px;
    padding-left: 15px;
    padding-right: 27px;
    padding-top: 12px;
    font-size: 1.3rem;
    margin-bottom: 8px;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.582), -1px -1px 2px white;
    letter-spacing: 2px;
    background-color: white;
    border-radius: 50%;
    font-weight: 600;
    margin-left: 5px;
    margin-right: 5px;

}
.add-button:hover{
    background-color: #4d4d4dc2; 
    color: white;
    box-shadow: 0px 0px 0px black;
}
.option-wrapper{
    display: grid;
    grid-template-columns: 1fr 30px;
    text-align: center;
}


.list-unit-wrapper{
    display: grid;
    grid-template-columns: 30px 1fr;
    text-align: center;
}
.option-wrapper .list-unit-wrapper{
    padding-right: 10px;

}
.maximum{
    width: 60px;
}


.name-change{
    text-align: center;
    margin-bottom: 10px;
}

input{
    font-size: 1.5rem;
    border-radius: 5px;
    padding: 8px;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    text-align: center;
    background-color: #eeeeeec2;
    font-weight: bold;
}
input.maximum{
    font-weight: normal;
    font-size: 1rem;
    margin-bottom: 5px;
    background-color: white;
}

.card-input{
    font-size: 1rem;
    border-radius: none;
    padding: 0;
    background-color: white;
    font-weight: normal;
    text-align: left;
}
.save-button{
    background-color: var(--red);
    color: white;
    width: 80px;
    padding: 5px;
    font-size: 1.2rem;
    cursor: pointer;
}

.container{
    padding-left: 10%;
    padding-right: 10%;
}

.header{
    text-align: center;
    font-size: 2rem;
    letter-spacing: 5px;
    font-weight: 600;
    color: var(--red);
    margin-bottom: 10px;
    text-shadow: 1px 1px 3px white;
    border-bottom: 4px solid black;
    text-transform: uppercase;
}
@media screen and (max-width: 580px) {
    .unit-card{
        font-size: .8rem;
    }
    .option-wrapper{
        padding-right: 30px;
        max-width: 90vw;
    }
    .list-unit-wrapper{
        max-width: 90vw;
    }
    .list, .unit-options, .unit-info{
        max-width: 100vw;
    }
    .unit-info{
        margin-left: 0px;
        margin-right: 0px;
        padding-left: 0px;
        padding-right: 0px;
    }

}

.back{
    cursor: pointer;
    padding: 5px;
    color: black;
    font-weight: bold;
    font-size: 1.2rem;
}

.back-wrapper{
    margin-bottom: 10px;
}
.faction-icon{
    width: 75px;
    padding-right: 20px;
    padding-left: 20px;
}

label{
}

.form-group{
    display: grid;
    grid-template-columns: 200px 300px;
    text-align: center;
    align-items: center;
    justify-content: center;
}
.chosen-weapon-wrapper{
    margin-left: 200px;    
    align-items: center;
    justify-content: center;
}

.chosen-special{
    border: 1px solid black;
    border-radius: 5px;
    padding: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: -5px;
    cursor: pointer;
}

.chosen-specials{
    margin-left: 200px;
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;
}

.chosen-weapon-wrapper{
    display: grid;
    grid-template-columns: 175px 40px 50px;
    margin-bottom: 10px;
}

.choose-location{

}

.main{
    padding-top: 40px;
}

.update-wrapper{
    margin: auto;
    border-top: 4px solid black;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 60px;
    margin-left: 30px;
    margin-right: 30px;
    padding-top: 30px;
}
.update-title{
    font-size: 2rem;
    text-align: center;
    font-weight: 600;
    letter-spacing: 3px;
}
.update-date{
    font-size: 1rem;
    color: rgb(66, 66, 66);
}
.update-body{
    padding-top: 30px;
    letter-spacing: 2px;
}

.news-header{
    border-bottom: none;
    font-size: 3rem;
}

.save-form{
    text-align: right;
}
.print>div{
    page-break-inside: avoid;
}
@media print
{    
    .navbar, .print-back, .print-print{
        display: none;
    }

}

.print-button{
    cursor: pointer;
    border: 1px solid black;
    padding: 7px;
    border-radius: 5px;
}

.print-back, .print-print{
    cursor: pointer;
    text-decoration: underline;
    color: blue;
    padding-left: 15px;
}
.print-button-wrapper{
    padding: 20px;
}

.summary{
    text-align: center;
    margin-bottom: 15px;
    font-size: 1.5rem;
    page-break-after: always;
}

.print-title{
    font-size: 2rem;
    text-decoration: underline;
}
.error{
    color: red;
    text-align: center;
}

.delete{
    cursor: pointer;
    border-radius: 3px;
    border: 1px solid black;
    padding-right: 10px;
    padding-left: 10px;
    padding-bottom: 5px;
    padding-top: 5px;
}

.list-list-item{
    margin: 20px;
}

.print-unit-wrapper{
    column-count: 2;
    font-size: 0.8rem;
}

.list-builder-bottom{
    margin-top: 25px;
    margin-bottom: 25px;
}

.confirm-delete{
    text-align: center;
    font-size: 1.2rem;
}
.cancel, .confirm{
    cursor: pointer;
}
.cancel{
    border: 1px solid black;
}
.confirm{
    color: red;
    border: 1px solid red;
}

.buy-link{
    text-align: center;
}

.option-warning{
    color: gray;
}

.collection{
    text-align: right;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 30px;
}
.profile-wrapper{
    display: grid;
    grid-template-columns: 1fr 1fr;
}

@media screen and (max-width: 750px) {
    .profile-wrapper{
        grid-template-columns: 1fr;
    }
}

.collection-input{
    width: 70px;
}

.button-wrapper{
    margin-top: 30px;
    margin-bottom: 30px;
    text-align: center;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.save-button-top{
    margin-left: 0px;
    margin-top: 0px;
    padding: 5px;
    width: 80px;
}

.builder-top{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.save-button-wrapper{
    text-align: center;
}

.save-copy-button{
    width: 120px;
    margin-left: 12px;
}

.top-mobile{
    display: none;
}

.top-mobile-buttons {
    margin-top: 12px;
    display: flex;
    justify-content: space-around;
}

@media screen and (max-width: 800px) {
    .top-desktop{
        display: none;
    }
    .top-mobile{
        display: block;
    }
    .builder-top {
        display: block;
    }
}

.print-top {
    display: flex;
    justify-content: space-between;
    padding: 20px;
}

.print-middle {
    display: flex;
    justify-content: space-around;
}

.print-company-name {
    font-weight: bold;
}

.print-bottom {
    display: flex;
    justify-content: space-around;
    margin-top: 12px;
}